<template>
    <div>
        <el-card class="intanle">
            <span class="h1">{{ title }}</span>
            <span @click="goBack()">
                <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
            </span>
        </el-card>
        <el-card>
            <!-- 情報入力 -->
            <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
                <el-row>
                    <el-col :span="12" v-if="type != 'insert'">
                        <el-form-item label="注文番号:" prop="orderNo">
                            <el-input :disabled="disabledOrder" v-model="form.orderNo" style="width: 55%">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="type != 'insert'">
                        <el-form-item label="注文状態:" prop="orderStatus">
                            <el-select :disabled="disabledOrder" v-model="form.orderStatus" placeholder="配送方法を選択してください"
                                style="width: 55%">
                                <el-option v-for="item in orderStatuslist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>

                    <el-col :span="12">
                        <el-form-item label="購入ユーザー:" prop="userName">
                            <el-button :class="form.userName == '購入ユーザーを選択してください' ? 'anjian' : 'anjians'" :style="{
                                    background: this.type == 'details' ? '#F5F7FA' : '#fff',
                                }" :disabled="true" @click="openme('ユーザーを選択してください')" style="width: 55%; text-align: left">
                                {{ form.userName }}
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="配送方法:" prop="deliveryType">
                            <el-select :disabled="true" v-model="form.deliveryType" @change="iswaimai"
                                placeholder="配送方法を選択してください" style="width: 55%">
                                <el-option v-for="item in deliveryTypelist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isziti">
                    <el-col :span="12">
                        <el-form-item label="配送会社:" prop="deliveryCompany">
                            <el-select :disabled="disabled" v-model="form.deliveryCompany" placeholder="配送会社を選択してください"
                                style="width: 55%">
                                <el-option v-for="item in deliveryCompanylist" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="配送番号:" prop="deliveryNo">
                            <el-input placeholder="配送番号を入力してください" :disabled="disabled" v-model="form.deliveryNo"
                                style="width: 55%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="実際の支払額:" prop="actualTotalPay">
                            <el-input @keyup.native="form.actualTotalPay = fudianshu(form.actualTotalPay)"
                                v-model="form.actualTotalPay" placeholder="実際の支払額を入力してください" :disabled="true"
                                style="width: 55%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="type != 'insert'">
                        <el-form-item label="商店名:" prop="merchantName">
                            <el-input v-model="form.merchantName" disabled style="width: 55%"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="24" style="margin-left: -10%;">
                        <el-form-item label="配送先住所:" prop="addressContentdizhi">
                            <el-button :class="form.addressContentdizhis == '配送先住所を選択してください' ? 'anjian' : 'anjians'" :style="{
                                    background: this.type == 'details' ? '#F5F7FA' : '#fff',
                                }" :disabled="false" @click="openme('配送先住所を選択してください')"
                                style="width: 90%; text-align: left">
                                {{ form.addressContentdizhis }}
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="this.type == 'insert'">
                    <el-col :span="12">
                        <el-form-item label="領収書発行:" prop="invoice">
                            <el-select v-model="form.invoice" style="width: 55%" :disabled="disabled">
                                <el-option v-for="item in invoiceList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.invoice == 'はい' && this.type == 'insert'">
                    <el-col :span="12">
                        <el-form-item label="領収書宛名:" prop="invoiceHeader">
                            <el-input placeholder="領収書宛名を入力してください" :disabled="disabled" v-model="form.invoiceHeader"
                                style="width: 55%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="税番号:" prop="taxNumber">
                            <el-input placeholder="税番号を入力してください" :disabled="disabled" v-model="form.taxNumber"
                                style="width: 55%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="備考:" prop="invoiceRemark">
                            <el-input placeholder="備考を入力してください" :disabled="disabled" v-model="form.invoiceRemark"
                                style="width: 55%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row v-if="this.type != 'insert' && form.invoiceInfo != ''">
                    <el-col :span="24">
                        <el-form-item label="发票:" prop="invoiceInfo" label-width="10%">
                            <el-input :disabled="disabled" v-model="form.invoiceInfo" rows="3" type="textarea"
                                style="width: 80%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="ユーザー備考:" prop="userRemark" label-width="10%">
                            <el-input :disabled="disabled" rows="5" type="textarea" v-model="form.userRemark"
                                style="width:80%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="istianjia">
                    <el-col :span="12">
                        <el-form-item label="" v-if="this.type != 'details'">
                            <el-button type="primary" @click="openme('商品を選択してください')">商品を追加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-for="(item, index) in ListCarts" :key="index">
                    <div v-if="!tables">
                        <el-col :span="5" style="margin-left: 5.7%;">
                            <el-form-item :label="'商品名:'" prop="productName1" style="width: 120%">
                                <el-input disabled v-model="item.productName1" style="width: 60%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" style="margin-left: 0.3%;">
                            <el-form-item :label="'商品価格:'" prop="productPrice" style="width: 120%">
                                <el-input disabled v-model="item.productPrice" style="width: 60%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5" style="margin-left: -1.5%;">
                            <el-form-item :label="'商品数量:'" prop="amount" style="width: 120%">
                                <el-input disabled v-model="item.amount" style="width: 60%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="false" :span="3" style="margin-left: -4%;">
                            <el-button v-if="type == 'details'" style="margin-left:15px ;position: relative;z-index: 1;"
                                type="primary" @click="shangpinxiangqing(item)">商品詳細</el-button>
                            <el-button v-if="!disabled" style="margin-left:15px ;position: relative;z-index: 1;"
                                type="primary" @click="shanchushangpin(index)" icon="el-icon-delete"></el-button>
                            <el-button v-if="!disabled && index == 0"
                                style="margin-left:15px ;position: relative;z-index: 1;" type="primary"
                                @click="openme('商品を選択してください')">添加商品</el-button>
                        </el-col>
                    </div>
                </el-row>


                <div class="logtime" v-if="this.type != 'insert'">
                    <el-row>
                        <el-col :span="12" v-if="confirmOrderTime != null && confirmOrderTime != '0001-01-01 00:00:00'">
                            <el-form-item label="注文確定日時:" label-width="20%">
                                <el-input disabled v-model="confirmOrderTime" style="width: 55%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px;">
                        <el-col :span="12" v-if="sendProductsTime != null && sendProductsTime != '0001-01-01 00:00:00'">
                            <el-form-item label="発送日時:" label-width="20%">
                                <el-input disabled v-model="sendProductsTime" style="width: 55%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12"
                            v-if="receivedProductsOrderTime != null && receivedProductsOrderTime != '0001-01-01 00:00:00'">
                            <el-form-item label="受取日時:" label-width="20%">
                                <el-input disabled v-model="receivedProductsOrderTime" style="width: 55%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px;">
                        <el-col :span="12" v-if="refundApplyTime != null && refundApplyTime != '0001-01-01 00:00:00'">
                            <el-form-item label="返品申請日時:" label-width="20%">
                                <el-input disabled v-model="refundApplyTime" style="width: 55%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" v-if="closedTime != null && closedTime != '0001-01-01 00:00:00'">
                            <el-form-item label="取引終了日時:" label-width="20%">
                                <el-input disabled v-model="closedTime" style="width: 55%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px;">
                        <el-col :span="24" v-if="refundRefusedReason != null && refundRefusedReason != ''">
                            <el-form-item label="返品拒否理由:" label-width="10%">
                                <el-input disabled v-model="refundRefusedReason" style="width: 80%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="logtime" v-if="this.type != 'insert'">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="作成日時:" label-width="20%">
                                {{ createTime }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="更新日時:" label-width="20%">
                                {{ updateTime }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="作成者:" label-width="20%">
                                {{ createPersonName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="更新者:" label-width="20%">
                                {{ updatePersonName }}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="" v-if="this.type != 'details'">
                            <el-button type="primary" @click="onSubmit('form')">{{
                                content
                            }}</el-button>
                            <el-button @click="goBack">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 相关商户弹出层 -->
            <el-dialog :title="isnagetanchaung" :visible.sync="table" width="80%" center>
                <GetUsersListVue v-if="isnagetanchaung == 'ユーザーを選択してください'" :istanchuang="istanchuang" ref="eidtList"
                    @submitForm="submitForm">
                </GetUsersListVue>
                <GetAddressesList v-if="isnagetanchaung == '配送先住所を選択してください'" :user="user" :istanchuang="istanchuang" ref="eidtList"
                    @submitForm="submitForm"></GetAddressesList>
                <!-- <span slot="footer" class="dialog-footer">
                                            <el-button @click="table = false">取 消</el-button>
                                            <el-button type="primary" @click="submitForm">確認</el-button>
                                        </span> -->
            </el-dialog>
            <el-dialog :title="isnagetanchaung" :visible.sync="tables" v-if="tables" width="80%" center>
                <GetProductsListVue v-if="isnagetanchaung == '商品を選択してください'" :ListCarts='ListCarts' :istanchuang="istanchuang"
                    :table="istanchuang" :merchantIdOrder="merchantIdOrder" ref="eidtList">
                </GetProductsListVue>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="quxiao">キャンセル</el-button>
                    <el-button type="primary" @click="submitForm">確認</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>

import GetUsersListVue from '../Users/GetUsersList.vue'
import GetAddressesList from '../Addresses/GetAddressesList.vue'
import GetProductsListVue from '../Products/GetProductsList.vue'
export default {
    inject: ["reload"],
    name: "GetStaffDetails",
    components: {
        GetUsersListVue,
        GetAddressesList,
        GetProductsListVue
    },
    watch: {
        form() {
            if (this.form.deliveryType == 1) {
                this.isziti = true
            }
        },
        ListCarts() {
            if (this.ListCarts.length == 0) {
                this.istianjia = true
            } else {
                this.istianjia = false
            }
        },
        tables() {
            if (this.tables) {
                this.beifens = JSON.parse(JSON.stringify(this.ListCarts))
            } else {
                for (let i = 0; i < this.ListCarts.length; i++) {
                    if (!this.ListCarts[i].amount) {
                        this.ListCarts.splice(i, 1)
                        i--
                    }
                }
            }
        }
    },
    data() {
        return {
            istianjia: true,
            // title:'ユーザーを選択してください',
            isziti: false,
            isxuanyonghu: true,
            isnagetanchaung: 'ユーザーを選択してください',
            istanchuang: true,
            merchantIdOrder: '',
            type: this.$route.query.type,
            orderId: this.$route.query.orderId,
            table: false,
            tables: false,
            loading: false,
            user: '',
            form: {
                userName: '購入ユーザーを選択してください',
                userId: '',
                relatedCouponId: '',
                addressContentdizhis: '配送先住所を選択してください',
                addressContentdizhi: '配送先住所を選択してください',
                addressContent: '',
                actualTotalPay: '',
                userRemark: '',
                merchantId: '',
                merchantName: '',
                deliveryType: '',
                deliveryCompany: '',
                deliveryNo: '',
                invoiceInfo: '',
                invoice: 'いいえ',
                invoiceHeader: '',//請求書タイトル
                taxNumber: '',//税番号
                invoiceRemark: ''//備考
            },
            ListCart: [],
            ListCarts: [],
            beifens: [],
            title: "",
            content: "",
            MyHeader: {
                accessToken: "",
                fp: ""
            },
            disabled: false,
            disabledOrder: false,
            // user: "購入ユーザーを選択してください",
            relatedCoupon: 'クーポンを選択してください',
            address: '配送先住所を選択してください',
            merchant: '関連店舗を選択してください',
            createTime: '',
            updateTime: '',
            createPersonName: '',
            updatePersonName: '',
            confirmOrderTime: '',
            sendProductsTime: '',
            receivedProductsOrderTime: '',
            refundApplyTime: '',
            closedTime: '',
            refundRefusedReason: '',
            headerName: "",
            invoiceList: [{ label: 'いいえ', value: 'いいえ' }, { label: 'はい', value: 'はい' }],
            deliveryTypelist: [{ label: '配送', value: '1' }],
            orderStatuslist: [
                { label: '未支払い', value: '10' },
                { label: '支払い済み', value: '20' },
                { label: '受注済み', value: '30' },
                { label: '発送中', value: '40' },
                { label: '受け取り済み', value: '50' },
                { label: '注文完了', value: '60' },
                { label: '返品申請中', value: '70' },
                { label: '返金中', value: '80' },
                { label: '返品返金完了', value: '90' },
                { label: '取引終了', value: '100' },
            ],
            deliveryCompanylist: [{ label: '宅急便', value: '宅急便' }],
            rules: {
                userName: [
                    {
                        required: true,
                        message: "ユーザーを選択してください",
                        trigger: "blur"
                    }
                ],
                deliveryType: [
                    {
                        required: true,
                        message: "配送方法を入力してください",
                        trigger: "blur"
                    }
                ],
                actualTotalPay: [
                    {
                        required: true,
                        message: "実際の支払額を入力してください",
                        trigger: "blur"
                    }
                ],
                addressContentdizhi: [
                    {
                        required: true,
                        message: "配送先住所を選択してください",
                        trigger: "blur"
                    }
                ],
                invoiceHeader: [
                    {
                        required: true,
                        message: "請求書のタイトルを入力してください",
                        trigger: "blur"
                    }
                ],
                taxNumber: [
                    {
                        required: true,
                        message: "税番号を入力してください",
                        trigger: "blur"
                    }
                ],
            }
        }
    },

    created() {
        this.MyHeader.accessToken = localStorage.getItem("accessToken")
        // this.MyHeader.fp = localStorage.getItem("fp")
        if (this.type == "details") {
            this.title = "注文管理詳細"
            this.headerName = "注文管理詳細"
            this.disabled = true
            this.disabledOrder = true
            this.GetOrderDetail()
        } else if (this.type == "update") {
            this.title = "注文管理修正"
            this.headerName = "注文管理修正"
            this.content = "保存"
            this.disabledOrder = true
            this.GetOrderDetail()
        } else if (this.type == "insert") {
            this.title = "注文管理新規追加"
            this.headerName = "注文管理新規追加"
            this.content = "新規追加"
        }
    },

    methods: {
        iswaimai() {
            if (this.form.deliveryType == 1) {
                this.isziti = true
            } else {
                this.isziti = false
            }
        },
        shangpinxiangqing(val) {
            this.$router.push({
                name: "GetProductsDetails",
                query: {
                    productId: val.productId,
                    type: "details",
                }
            })
        },
        shanchushangpin(val) {
            this.ListCart.splice(val, 1)
            this.ListCarts.splice(val, 1)
        },
        //注文管理詳細
        async GetOrderDetail() {
            let params = {
                        orderId: this.orderId,
                        accessToken: localStorage.getItem('authToken'),
                        currentRole: localStorage.getItem('currentRole')
                    }
            const { data: res } = await this.$http
                .post(this.$url.GetOrderDetail, params)
            if (res.success == 'true') {
                this.form = res.result[0]
                this.merchantIdOrder = res.result[0].merchantId
                this.form.addressContentdizhi = res.result[0].addressContent
                this.form.addressContentdizhis = res.result[0].addressContent
                this.createTime = res.result[0].createTime
                this.updateTime = res.result[0].updateTime
                this.createPersonName = res.result[0].createPersonName
                this.updatePersonName = res.result[0].updatePersonName
                this.confirmOrderTime = res.result[0].confirmOrderTime
                this.sendProductsTime = res.result[0].sendProductsTime
                this.receivedProductsOrderTime = res.result[0].receivedProductsOrderTime
                this.refundApplyTime = res.result[0].refundApplyTime
                this.closedTime = res.result[0].closedTime
                this.refundRefusedReason = res.result[0].refundRefusedReason
                this.ListCarts = []
                this.ListCarts = res.oldDetailList
                // this.ListCart = res.dtOrderdetailList
                this.ListCart = []
                for (let i = 0; i < res.oldDetailList.length; i++) {
                    this.ListCart.push(
                        {
                            productId: res.oldDetailList[i].productId,
                            productPatternId: res.oldDetailList[i].productPatternId,
                            putInCartAmount: Number(res.oldDetailList[i].amount),
                            patternPrice: res.oldDetailList[i].patternPrice,
                        }
                    )
                    this.ListCarts[i].productPrice = res.oldDetailList[i].patternPrice
                }
            } else {
                this.$message.error(res.message)
            }
        },

        //注文管理　新規
        onSubmit(formName) {
            if (this.form.deliveryType == 2) {
                this.form.deliveryCompany = ''
                this.form.deliveryNo = ''
            }

            if (this.type == "insert") {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        if (this.form.invoice == "はい") {
                            this.form.invoiceInfo = '請求書タイトル（会社名）：' + this.form.invoiceHeader + '\n' + '会社税番号：' + this.form.taxNumber + '\n' + '備考：' + this.form.invoiceRemark
                        } else {
                            this.form.invoiceInfo = ''
                        }
                        const data1 = {
                            addressContent: this.form.addressContentdizhi, // 
                            userRemark: this.form.userRemark, //
                            deliveryType: this.form.deliveryType, // 
                            deliveryCompany: this.form.deliveryCompany, // 
                            deliveryNo: this.form.deliveryNo, // 
                        }
                        const { data: res } = await this.$http
                            .post(this.$url.AddOrder, data1, {
                                headers: {
                                    "Content-Type":
                                        "application/json;charset=UTF-8"
                                }
                            })
                        if (res.msgCode == 1) {
                            this.$message.success('保存成功')
                            this.$router.push({
                                path: "/GetOrdersList"
                            })
                        } else {
                            this.$message.error(res.message);
                        }
                    }
                })
            } else if (this.type == "update") {
                this.UpdateStaff(formName)
            }
        },
        //注文管理編集
        async UpdateStaff(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    var data = {
                        accessToken: localStorage.getItem('authToken'),
                        currentRole: localStorage.getItem('currentRole'),
                        orderId: this.form.orderId,
                        addressContent: this.form.addressContentdizhi,
                        userRemark: this.form.userRemark,
                        deliveryType: this.form.deliveryType,
                        deliveryCompany: this.form.deliveryCompany,
                        deliveryNo: this.form.deliveryNo,
                    }
                    /**
                     * 
                        ListCart: this.ListCart,
                        invoiceInfo: this.form.invoiceInfo,
                        MerchantId: this.$url.merchantId,
                        relatedCouponId: this.form.relatedCouponId,
                        actualTotalPay: Number(this.form.actualTotalPay),
                        userId: this.form.userId,
                     */
                    const { data: res } = await this.$http.post(
                        this.$url.UpdateOrder,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8"
                            }
                        }
                    )
                    if (res.success === 'true') {
                        this.$message.success("情報の修正に成功しました!!")
                        localStorage.setItem('updateState', true)
                        this.$router.push({
                            path: "/GetOrdersList",
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                } else {
                    return false
                }
            })
        },
        // 関連店舗を開く
        openme(val) {
            this.isnagetanchaung = val
            if (val != '商品を選択してください') {
                this.table = true
            } else {
                this.beifens = JSON.parse(JSON.stringify(this.ListCarts))
                this.tables = true
            }
        },
        quxiao() {
            this.tables = false
            this.ListCarts = JSON.parse(JSON.stringify(this.beifens))
        },
        //前のページに戻る
        goBack() {
            localStorage.setItem('updateState', true)
            this.$router.go(-1)
        },
        submitForm() {
            var item = this.$refs["eidtList"].passFather();
            if (item == null) {
                item = []
            }
            if (item.length != 0) {
                if (this.isnagetanchaung == 'ユーザーを選択してください') {
                    // 获取子组件填写的内容
                    this.form.userName = item.userName
                    this.user = item.userId
                    this.form.userId = item.userId
                    this.form.addressContent = ''
                    this.form.addressContentdizhi = '配送先住所を選択してください'
                    this.form.addressContentdizhis = '配送先住所を選択してください'
                    if (this.form.userName != '購入ユーザーを選択してください') {
                        this.isxuanyonghu = false
                    }
                } else if (this.isnagetanchaung == '配送先住所を選択してください') {
                    this.form.addressContent = item.addressId
                    this.form.addressContentdizhi = item.consigneeName + '<br>' + item.consigneeTel + '<br>' + item.consigneeAddress
                    this.form.addressContentdizhis = item.consigneeName + '\xa0\xa0\xa0\xa0' + item.consigneeTel + '\xa0\xa0\xa0\xa0' + item.consigneeAddress
                } else if (this.isnagetanchaung == '商品を選択してください') {
                    this.ListCart = []
                    this.ListCarts = []
                    for (let i = 0; i < item.length; i++) {
                        this.ListCart.push({
                            productId: item[i].productId,
                            productPatternId: item[i].productPatternId,
                            putInCartAmount: Number(item[i].amount),
                            patternPrice: item[i].patternPrice,
                        })
                        this.ListCarts.push(item[i])
                        this.ListCarts[i].amount = item[i].amount
                        this.ListCarts[i].productPrice = item[i].patternPrice
                    }
                }
            }
            this.table = false
            this.tables = false
        },
        fudianshu(val) {
            let str = val.toString()
            var len1 = str.substr(0, 1);
            var len2 = str.substr(0, 1);
            if (str.length > 1 && len1 == 0 && len2 != '.') {
                str = substr(1, 1)
            }
            if (len1 == '.') {
                str = ''
            }
            if (str.indexOf(".") != -1) {
                var str1 = str.substr(str.indexOf(".") + 1)
                if (str1.indexOf(".") != -1) {
                    str = str.substr(0, str.indexOf(".") + str1.indexOf(".") + 1)
                }
                if (str1.length > 2) {
                    var str2 = str.substr(0, str.indexOf(".") + 1)
                    var str3 = str.substr(str.indexOf(".") + 1, 2)
                    return (str = str2 + str3)
                }
            }
            str = str.replace(/[^\d^\.]+/g, "")
            return str
        },
    }
}
</script>

<style lang="less" scoped>
.el-button1 {
    margin-top: 2px;
}

.el-card {
    margin-top: 15px;
}

textarea {
    height: 100px;
}
</style>
