<template>
  <div>
   
    <el-card class="intanle">
      <span class="h1">商品管理リスト</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <span>商品名：</span>
          <el-input v-model="productName" placeholder="商品名を入力してください" style="width: 19%"></el-input>
          <span style="margin-left: 15px">カテゴリ：</span>
          <el-select v-model="categoryId" placeholder="カテゴリを選択してください" style="width: 19%">
            <el-option v-for="item in categorylist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <span style="margin-left: 15px">推奨タイプ：</span>
          <el-select v-model="recommandType" placeholder="推奨タイプを選択してください" style="width: 19%">
            <el-option v-for="item in recommandTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <span v-if="!istanchuang" style="float: right; z-index: 5; position: relative">
            <el-button style="margin-left: 20px;border: none" type="primary" @click="searchList">検索</el-button>
            <el-button type="info" style="border: none" @click="resetList">リセット</el-button>
          </span>
          <p v-if="!istanchuang">
            <span style="float: left">
              <el-button @click="toinsert" type="primary" icon="el-icon-plus" style="background-color: #2a4b9b; border: none">新規</el-button>
            </span>
          </p>
        </el-col>
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        @current-change="selectThisLine"
        :highlight-current-row="istanchuang"
        ref="multipleTables"
        v-loading="loading"
        :data="productslist"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="商品名" prop="productName1"></el-table-column>
        <el-table-column label="所属商人" prop="merchantName"></el-table-column>
        <el-table-column label="最低価格" prop="lowestPrice"></el-table-column>
        <el-table-column label="元の価格" prop="originalPrice"></el-table-column>
        <el-table-column label="商品カテゴリ" prop="categoryName"></el-table-column>
        <el-table-column label="推奨タイプ">
          <template slot-scope="scope">
            <span v-if="scope.row.recommandType == 1">人気商品</span>
            <span v-if="scope.row.recommandType == 2">SALE</span>
            <span v-if="scope.row.recommandType == 3">新着</span>
          </template>
        </el-table-column>
        <el-table-column label="SALE中">
          <template slot-scope="scope">
            <span v-if="scope.row.isSale == 1">はい</span>
            <span v-if="scope.row.isSale == 0">いいえ</span>
          </template>
        </el-table-column>
        <el-table-column label="利用可能">
          <template slot-scope="scope">
            <span v-if="scope.row.enabled == 1">はい</span>
            <span v-if="scope.row.enabled == 0">いいえ</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" v-if="!istanchuang">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleupdate(scope.$index, scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff" v-if="role == 1 || (role == 2 && merchantId == scope.row.merchantId)">編集</el-button>
            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="small" class="el-icon-delete" style="color: #da0909">削除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
    </el-card>
  </div>
</template>

<script>
import productService from '@/api/product/productService'
import categoryService from '@/api/category/categoryService'
export default {
  props: {
    istanchuang: {
      type: Boolean,
      default: false
    },
    productNameVal: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      categoryService: new categoryService(), //インターフェース定義
      productService: new productService(), //インターフェース定義
      anniushixiao: true,
      productName: '', //商品名称
      categoryId: '', //カテゴリ
      recommandType: '', //推荐类型
      currentPage: 1, //ページ数
      pageSize: 10, //1ページあたりの件数
      total: 0, //总数
      productslist: [], //列表数据
      loading: true, //表格加载loading
      productId: '', //商品id
      multipleSelection: [], //多选内容
      //分类下拉选项
      categorylist: [],
      // 推荐类型下拉选项
      recommandTypeList: [
        { label: '人気商品', value: '1' },
        { label: 'SALE', value: '2' },
        { label: '新着', value: '3' }
      ],
      newIsConfirmed: this.isConfirmed
    }
  },
  created() {
    this.merchantId = window.localStorage.getItem('merchantId')
    this.role = window.localStorage.getItem('currentRole')
    this.GetMerchantCategoryList()
    this.GetProductList()
  },
  methods: {
    // チェックボックスの値を取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
        this.slideId = val[0].slideId
      }
    },
    // 父级的弹出层选中的数据
    selectThisLine(row) {
      this.item = row
      this.$emit('submitFormSP')
      // return item
    },
    // 传给父级
    passFather() {
      return this.item //this.ruleForm是子组件填写的数据
    },
    /* ページサイズ変更イベントのリスナー */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetProductList()
    },
    /* ページ番号変更イベントのリスナー */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetProductList()
    },
    //リスト取得
    async GetProductList() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        productName: this.productName,
        categoryId: this.categoryId,
        recommandType: this.recommandType,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }
      this.productService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.productslist = res.data.result
          this.loading = false
          this.total = Number(res.data.maxRecords)
          for (var i = 0; i < this.productslist.length; i++) {
            if (this.productslist[i].enabled == 1) {
              this.productslist[i].enabled = true
            } else {
              this.productslist[i].enabled = false
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //照会
    async searchList() {
      this.currentPage = 1
      this.GetProductList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.$router.push({
        name: 'GetProductsDetails',
        query: {
          productId: row.productId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(index, row) {
      this.$router.push({
        name: 'GetProductsDetails',
        query: {
          productId: row.productId,
          type: 'update'
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.$router.push({
        name: 'GetProductsDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      this.productName = ''
      this.categoryId = ''
      this.recommandType = ''
      this.currentPage = 1
      if (this.table) {
        this.GetProductLists()
      } else {
        this.GetProductList()
      }
    },
    //1行削除
    handleDelete(row) {
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            productId: row.productId
          }
          this.productService.delete(date).then(res => {
            if (res.data.success == 'true') {
              this.$message({
                type: 'success',
                message: '削除成功!'
              })
              this.GetProductList()
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(err => {
          this.GetProductList()
        })
    },
    // 複数選択削除
    async handleDelete1() {
      var data1 = this.multipleSelection
      var data2 = []
      if (this.multipleSelection == undefined) {
        this.$confirm('データを選択してください', '表示', {
          type: 'warning'
        })
      } else {
        this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            for (var i = 0; i < data1.length; i++) {
              data2.push(data1[i].productId)
            }
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            if (this.table) {
              this.GetProductLists()
            } else {
              this.GetProductList()
            }
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err
            })
          })
      }
    },
    handleConfirmedSH(row) {
      this.$confirm('この商品を承認しようとしています。続行しますか？', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          productId: row.productId
        }
        const { data: res } = await this.$http.post(this.$url.ConfirmProduct, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        if (res.msgCode == 1) {
          this.$message.success('商品が承認されました')
          this.GetProductList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleConfirmedBH(row) {
      this.$confirm('この商品を拒否しようとしています。続行しますか？', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          productId: row.productId
        }
        const { data: res } = await this.$http.post(this.$url.RejectProduct, data, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        if (res.msgCode == 1) {
          this.$message.success('商品已驳回')
          this.GetProductList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //获取カテゴリ
    async GetMerchantCategoryList() {
      this.loading = false
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        currentPage: 1, //ページ数
        pageSize: 50 //1ページあたりの件数
      }
      this.categoryService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.loading = false
          res.data.result.forEach(item => {
            this.categorylist.push({
              label: item.categoryName,
              value: item.categoryId
            })
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cl-hui {
  color: #b2b2b2 !important;
}

.cl-bl {
  color: #606262;
}

.xuan1 {
  margin-top: 1px !important;
  width: 15px;
  height: 15px;
  border: 1px solid #dcdfe6;
  margin-left: 10px;
}

.xuan2 {
  margin-top: 1px !important;
  width: 15px;
  height: 15px;
  border: 1px solid #dcdfe6;
  background-color: #409eff;
  margin-left: 10px;

  // vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
    // vertical-align: middle;
    margin-bottom: 8px;
    display: inline-block;
  }
}

/deep/.el-table td.el-table__cell div {
  /* box-sizing: border-box; */
  margin-top: -2px !important;
}
</style>
